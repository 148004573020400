import React from 'react'
import moment from 'moment'
import { IntlProvider } from 'react-intl'
import { addLocaleDataFor } from './src/utils'
import { CookieBanner } from './src/components/cookie-banner'
import { LiveChatWidget } from './src/components/live-chat'
import languages from './src/locales'
import { useLocale } from './src/hooks/use-locale'
import { PageContextProvider } from './src/hooks/use-page-context'

addLocaleDataFor(languages)

class IntlLoader extends React.Component {
  constructor(props) {
    super(props)
    // sets initial messages from window (this is set in onRenderBody ssr hook)
    this.state = {
      messages: window.__messages,
      language: window.__language,
    }
    if (this.state.language) {
      moment.locale(this.state.language)
    }
  }

  /**
   * Async loads messages and set state.
   */
  loadMessages(language) {
    return import(`./src/locales/${language}.json`).then((messages) => {
      this.setState({ messages, language })
    })
  }

  loadMomentLocales(language) {
    import(`moment/locale/${language}`).then(() => {
      moment.locale(language)
    })
  }

  // Note: in development onRenderBody is rendered only once for all pages
  componentDidMount() {
    this.loadMomentLocales(this.props.language)
    this.loadMessages(this.props.language)
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.loadMessages(this.props.language)
      this.loadMomentLocales(this.props.language)
    }
  }

  render() {
    const { language, ...props } = this.props

    return (
      <IntlProvider
        locale={language}
        messages={this.state.messages}
        {...props}
      />
    )
  }
}

function Localized(props) {
  const locale = useLocale()
  return <IntlLoader language={locale} {...props} />
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <PageContextProvider value={props.pageContext}>
      <Localized {...props}>
        <>
          {element}
          <CookieBanner />
          <LiveChatWidget />
        </>
      </Localized>
    </PageContextProvider>
  )
}
